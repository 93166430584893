// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')

import $ from 'jquery';
import feather from 'feather-icons/dist/feather';
import Rails from '@rails/ujs';
import Noty from "noty";
import 'noty/lib/noty';
import '../css/noty.css';
import 'noty/lib/themes/mint.css';
import 'expose-loader?Noty!noty';
import MicroModal from 'micromodal';
import noUiSlider from 'nouislider';
import 'nouislider/distribute/nouislider.min.css';
import ClipboardJS from 'clipboard';
import songsPlayer from 'songsPlayer';
import '../css/styles';


var wavesurfers = [];
var player = null;

$(document).on('turbolinks:load', function() {

  if (!player) {
    player = new songsPlayer();
  }

  if ($('.songs-list').length == 0) {
    player.stopAll()
  } else {
    player.loadSongs();
  }

  feather.replace();

  var checkBoxes = document.querySelectorAll("#filters-form input[type=checkbox]");
  var filtersForm = document.querySelector('#filters-form');

  for (const check of checkBoxes) {
    check.addEventListener( 'change', function() {
      Rails.fire(filtersForm, 'submit');
      updateFilterCount($(this));
    });
  }

  $(document).off('click');

  $(document).on("ajax:send", $(filtersForm), (event) => {
    if ($('input.filters-search').length && $('input.filters-search').val().length) {
      resetFiltersUI();
      $('input.filters-search').val("");
    }
    loadSpinner();
  });

  $(document).on("ajax:success", $(filtersForm), (event) => {
    feather.replace();
    player.loadSongs();
  });


  if ($('.filters').length) { filtersMenu() }


  $('.show-sub-menu').on('click', function(e){
    $('.user-menu .sub-menu').toggleClass('open');
    e.stopPropagation();
  });

  $(document).on('click', function(){
    $('.user-menu .sub-menu.open').removeClass('open')
  });

  $(document).on('click', '.song-row a.download', function(e){
    if (!userLoggedIn) {
      MicroModal.show('modal-signup');
      e.preventDefault()
    }
  });

  $(document).on('click', '.add-to-favorites', function(){
    addToFavorites(this);
  });

  $(document).on('click', '.show-playlist-modal', function(){
    showPlaylistModal(this);
  });

  $('.new-playlist, .edit-playlist').on("ajax:success", function(event) {
    MicroModal.show('modal-playlist');
  });

  $(document).on('click', '.pagination a', function(e){
    e.preventDefault();
    scrollToTop();
    loadSpinner();
    Rails.ajax({
      type: "GET",
      url: $(this).attr('href'),
      dataType: 'script',
      success: function(response){
        feather.replace();
        player.loadSongs();
      },
      error: function(response){
        console.log("Error: " + response);
      }
    })
  });

  $(document).on('click', '.collections a, .songs-result-title .reset', function(e){
    e.preventDefault();
    resetFiltersUI();
    loadSpinner();
    Rails.ajax({
      type: "GET",
      url: $(this).attr('href'),
      dataType: 'script',
      success: function(response){
        feather.replace();
        player.loadSongs();
      },
      error: function(response){
        console.log("Error: " + response);
      }
    })
  });

  $('.remove-from-playlist').on('click', function(){
    removeFromPlaylist(this)
  });

  $('.license_types_list .action').on('click', function(e){
    e.preventDefault();
    $(this).parent().siblings('li').find('ul').hide();
    $(this).parent().siblings('li').find('.active').removeClass('active');
    $(this).next('ul').show();
    $(this).addClass('active');
  });

  $('input[name=license_type]').on('change', function(e){
    checkoutEvents($(this));
  });

  if ($('#bpm-slider').length) {
    bpmSlider();
  }

  if ($('#length-slider').length) {
    lengthSlider();
  }

  $(document).on('click', '.show-filters, .close-filters', function(){
    $('.filters').toggleClass('show');
  });

  $(document).on('click', '.show-mobile-menu, .hide-mobile-menu', function(){
    $('.mobile-menu').toggleClass('show');
  });

  if ($('.copy-playlist-url').length) {
    var clipboard = new ClipboardJS('.copy-playlist-url');
    clipboard.on('success', function(e) {
      new Noty({
        type: 'info',
        text: 'Playlist url copied!',
        timeout: 5000
      }).show();
    });
  }

  if (iOS) {
    var hideSetting = localStorage.getItem('iOSAudioMessage');
    if (hideSetting != 'hide') {
      $('.ios-silent-mode').show();
      $(document).on('click', '.ios-silent-mode .close', function(){
        $('.ios-silent-mode').hide();
        localStorage.setItem('iOSAudioMessage', 'hide');
      });
    }
  }

});


function checkoutEvents($el) {
  if (typeof fbq !== 'undefined') {
    fbq('track', 'InitiateCheckout', {
      content_ids: $el.data('song'),
      content_type: 'product',
      num_ids: 1,
      value: $el.data('amount'),
      currency: 'USD'
    });
  }
  dataLayer.push({
    'event': 'checkout',
    'ecommerce': {
      'checkout': {
        'actionField': {'step': 1, 'option': 'Payment'},
        'products': [{
          'id': $el.data('song'),
          'price': $el.data('amount'),
          'quantity': 1
       }]
     }
   }
  });
}

function filtersMenu() {
  var accordion_head = $('.filters .context-name'),
  accordion_body = $('.filters .tags-list');

  // Open the first tab on load
  accordion_head.first().addClass('active').next().slideDown('normal');

  accordion_head.on('click', function(event) {
    // Disable header links
    event.preventDefault();
    // Show and hide the tabs on click
    if ($(this).attr('class') != 'active'){
        accordion_body.slideUp('normal');
        $(this).next().stop(true,true).slideToggle('normal');
        accordion_head.removeClass('active');
        $(this).addClass('active');
    }
  });
}

function updateFilterCount($checkbox) {
  var $wrapper = $checkbox.parents().eq(3);
  var $name = $wrapper.find('.context-name');
  var count = $wrapper.find('input:checked').length;

  if ($name.find('.count').length) {
    if (count > 0) {
       $name.find('.count').text(count)
    } else {
      $name.find('.count').remove()
    }
  } else {
    $name.append('<span class="count">' + count + '</span>')
  }
}

function resetFiltersUI() {
  $("#filters-form input:checkbox").prop('checked', false);
  $('#length-slider')[0].noUiSlider.reset();
  $('#bpm-slider')[0].noUiSlider.reset();
  $('span.count').remove();
}

function addToFavorites(button) {
  if (!userLoggedIn) {
    MicroModal.show('modal-signup');
  } else {
    var $button = $(button);
    var action = ($button.hasClass('favorited') ? 'remove_song' : 'add_song');
    $button.prop('disabled', true);
    Rails.ajax({
      type: "PUT",
      url: "/playlists/"+$button.data('playlist-id')+"/"+action+"/"+$button.data('song-id'),
      dataType: 'json',
      // data: qs.stringify(data, { arrayFormat: 'brackets' }),
      success: function(response){
        $('.favorite-button-' + $button.data('song-id')).toggleClass('favorited');
        $button.prop('disabled', false);
        if (typeof fbq !== 'undefined') {
          if (action == "add_song") {
            fbq('track', 'AddToWishlist', {
              content_ids: $button.data('song-id'),
              content_type: 'product'
            });
          }
        }
      },
      error: function(response){
        console.log("Error: " + response)
        $button.prop('disabled', false);
      }
    })
  }
}

function showPlaylistModal(button) {
  if (!userLoggedIn) {
    MicroModal.show('modal-signup');
  } else {
    var $button = $(button);
    $button.prop('disabled', true);
    Rails.ajax({
      type: "GET",
      url: "/songs/"+$button.data('song-id')+"/add_to_playlist/",
      dataType: 'script',
      success: function(response){
        MicroModal.show('modal-playlist');
        addToPlaylist($button.next('.add-to-favorites'));
        $button.prop('disabled', false);
      },
      error: function(response){
        console.log(response);
        $button.prop('disabled', false);
      }
    })
  }
}

function addToPlaylist(favoriteButton) {
  $('.add-to-playlist').on('click', function(){
    var $button = $(this);
    var playListId = $('#playlist_select').val();
    var isFavorite = (parseInt(playListId) === favoriteButton.data('playlist-id'));
    $button.prop('disabled', true);
    Rails.ajax({
      type: "PUT",
      url: "/playlists/"+playListId+"/add_song/"+$button.data('song-id'),
      dataType: 'json',
      success: function(response){
        MicroModal.close('modal-playlist');
        if (isFavorite) {
          favoriteButton.addClass('favorited')
        } else {
          new Noty({
            type: 'info',
            text: 'Added to playlist. <a href=/playlists/' + playListId +'>View</a>',
            // timeout: 5000
          }).show();
        }
      },
      error: function(response){
        console.log("Error: " + response)
        $button.prop('disabled', false);
      }
    });
  });
}

function removeFromPlaylist(button) {
  var $button = $(button);
  $button.prop('disabled', true);
  Rails.ajax({
    type: "PUT",
    url: "/playlists/"+$button.data('playlist-id')+"/remove_song/"+$button.data('song-id'),
    dataType: 'json',
    success: function(response){
      $button.closest('.song-row').remove()
    },
    error: function(response){
      console.log("Error: " + response)
      $button.prop('disabled', false);
    }
  })
}

function bpmSlider() {
  var $bpmRange = $('#bpm-slider');
  var minBpm = $bpmRange.data('min-range')
  var maxBpm = $bpmRange.data('max-range')

  noUiSlider.create($bpmRange[0], {
      range: {
          'min': minBpm,
          'max': maxBpm
      },
      start: [minBpm, maxBpm],
      pips: {
        mode: 'range',
        stepped: true,
        density: 4
      },
      tooltips: true,
      connect: true,
      step: 1,
      behaviour: 'drag-tap',
      format: {
        from: function(value) {
            return parseInt(value);
          },
        to: function(value) {
            return parseInt(value);
          }
        }
  });

  $bpmRange[0].noUiSlider.on('change', function() {
    var value = $bpmRange[0].noUiSlider.get();
    $('.bpm-checkboxes').each(function(){
      if (parseInt($(this).val()) >= value[0] && parseInt($(this).val()) <= value[1]) {
        $(this).prop("checked", true);
      } else {
        $(this).prop("checked", false);
      }
    });
    Rails.fire(document.querySelector('#filters-form'), 'submit');
  });

}

function lengthSlider() {
  var $lengthRange = $('#length-slider');
  var minLength = $lengthRange.data('min-range')
  var maxLength = $lengthRange.data('max-range')

  noUiSlider.create($lengthRange[0], {
      range: {
          'min': minLength,
          'max': maxLength
      },
      start: [minLength, maxLength],
      pips: {
        mode: 'range',
        stepped: true,
        density: 4,
        format: {to: parseDuration}
      },
      tooltips: [{to: parseDuration}, {to: parseDuration}],
      connect: true,
      step: 1,
      behaviour: 'drag-tap',
      format: {
        from: function(value) {
            return parseInt(value);
          },
        to: function(value) {
            return parseInt(value);
          }
        }
  });

  var form = document.querySelector('#filters-form');

  $lengthRange[0].noUiSlider.on('change', function() {
    var value = $lengthRange[0].noUiSlider.get();
    $('#length_range').val(value);
    Rails.fire(form, 'submit');
  });

}

function parseDuration(value) {
  var duration = parseInt(value);
  var minutes = Math.floor(value / 60);
  var seconds = Math.round(value - minutes * 60);
  if (seconds < 10) {seconds = "0"+seconds;}
  return minutes + ':' + seconds;
}

function loadSpinner() {
  $('#songs').html('<div class="spinner"><div class="rect1"></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div><div class="rect5"></div></div>');
}


const scrollToTop = () => {
  // Let's set a variable for the number of pixels we are from the top of the document.
  const c = document.documentElement.scrollTop || document.body.scrollTop;

  // If that number is greater than 0, we'll scroll back to 0, or the top of the document.
  // We'll also animate that scroll with requestAnimationFrame:
  // https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    // ScrollTo takes an x and a y coordinate.
    // Increase the '10' value to get a smoother/slower scroll!
    window.scrollTo(0, c - c / 10);
  }
};

const iOS = !window.MSStream && /iPad|iPhone|iPod/.test(navigator.userAgent);