import {Howl, Howler} from 'howler';
import MicroModal from 'micromodal';

class songsPlayer {
  constructor() {
    this.songs = [];
    this.controlBar, this.controlSong, this.volumeSlider;
    this.player = {
      playing: false,
      song: "",
      volume: localStorage.getItem('volume') ? localStorage.getItem('volume') : 0.8,
      previousVolume: 0.8,
      howl: null
    }
    this.setControlBar()
    this.loadSongs();
    this.setWindowEvents();
  }

  loadSongs() {
    var songContainers = document.querySelectorAll('.songs-list .song-row');
    this.loadWaveforms(songContainers);
  }

  loadWaveforms(songContainers) {
    songContainers.forEach((songContainer, index) => {
      var song = this.songs.find(song => song.id === songContainer.id);
      if (!song) {
        fetch(songContainer.dataset.peaks)
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            const sound = new Howl({
              src: [songContainer.dataset.audio],
              preload: false
            });
            var song = {
              id: songContainer.id,
              peaks: data.data,
              container: songContainer,
              url: songContainer.dataset.audio,
              howl: sound
            }
            this.drawWaveform(song.container, song.peaks);
            this.setAudioEvents(song);
            this.songs.push(song);
          });
      } else {
        song.container = songContainer;
        this.drawWaveform(song.container, song.peaks);
        this.setAudioEvents(song);
        this.setControlBar();
      }
    });
  }

  drawWaveform(container, peaks) {
    const waveformContainer = container.querySelector('.song-waveform');
    if (window.getComputedStyle(waveformContainer).display === "none") return;
    const backgroundContainer = waveformContainer.querySelector('.wave-background');
    backgroundContainer.innerHTML = "";
    const progressContainer = waveformContainer.querySelector('.wave-progress');
    progressContainer.innerHTML = "";

    const canvas = document.createElement('canvas');
    canvas.width = Math.round(waveformContainer.offsetWidth);
    const height = 40;
    canvas.height = height;

    const NUMBER_OF_BUCKETS = Math.round(canvas.width/4);
    let bucketDataSize = Math.floor(peaks.length / NUMBER_OF_BUCKETS);
    let buckets = [];
    for (var i = 0; i < NUMBER_OF_BUCKETS; i++) {
        let startingPoint = i * bucketDataSize;
        let endingPoint = i * bucketDataSize + bucketDataSize;
        let max = 0;
        for (var j = startingPoint; j < endingPoint; j++) {
          if (peaks[j] > max) {
              max = peaks[j];
          }
        }
        let size = Math.abs(max);
        buckets.push(size);
    }

    const ctx = canvas.getContext('2d');
    ctx.beginPath();
    buckets.map((bucket, i) => {
      if (bucket < 0.04) {
        bucket = 0.04;
      }
      ctx.moveTo((i * 4)+2, (height/2)+((bucket*height)/2));
      ctx.lineTo((i * 4)+2, (height/2)-((bucket*height)/2));
    });
    ctx.lineWidth = 2;
    ctx.stroke();

    backgroundContainer.appendChild(canvas);

    const progressCanvas = document.createElement('canvas');
    const progressContext = progressCanvas.getContext('2d');

    progressCanvas.width = canvas.width;
    progressCanvas.height = canvas.height;
    progressContext.drawImage(canvas, 0, 0);

    progressContainer.appendChild(progressCanvas);

  }

  resizeWaveforms() {
    this.songs.forEach((song, index) => {
      this.drawWaveform(song.container, song.peaks);
    });
    if (this.player.howl) {
      var currentSong = this.songs.find(song => song.id === this.player.song);
      this.drawWaveform(this.controlSong, currentSong.peaks);
    }
  }

  setWindowEvents() {
    window.addEventListener('resize', this.resizeWaveforms.bind(this));
  }

  setAudioEvents(song) {

    if (this.player.playing && this.player.song == song.id) {
      this.playing(song);
    }

    const playPauseButton = song.container.querySelector('.artwork-button');
    playPauseButton.addEventListener('click', event => {
      if (this.player.song != song.id) {
        this.loadSong(song);
      } else {
        if (this.player.playing) {
          song.howl.pause();
        } else {
          song.howl.play();
        }
      }
    });

    const waveformContainer = song.container.querySelector('.song-waveform');
    waveformContainer.addEventListener('click', e => {
      if (song.howl.duration() == 0 || this.player.song != song.id) {
        this.loadSong(song);
      } else {
        var x = ((e.pageX - waveformContainer.offsetLeft)/waveformContainer.offsetWidth);
        this.seekTo(song, x)
      }
    });

    song.howl.on('load', (e) => {
      song.container.classList.remove('loading');
      this.controlSong.classList.remove('loading');
    });

    song.howl.on('play', (e) => {
      this.playing(song);
    });


    song.howl.on('pause', (e) => {
      this.player.playing = false;
      song.container.classList.remove('playing');
      this.controlSong.classList.remove('playing');
    });

    song.howl.on('end', (e) => {
      song.howl.stop();
      this.player.playing = false;
      song.container.classList.remove('playing');
      this.controlSong.classList.remove('playing');
    });

    const progress = song.container.querySelector('.wave-progress');
    const controlProgress = this.controlSong.querySelector('.wave-progress');
    song.howl.on('seek', (e) => {
      progress.style.width = (((song.howl.seek() / song.howl.duration()) * 100) || 0) + '%';
      controlProgress.style.width = (((song.howl.seek() / song.howl.duration()) * 100) || 0) + '%';
    });

  }

  playing(song) {
    song.howl.volume(this.player.volume);
    song.container.classList.add('playing');
    this.controlSong.classList.add('playing');
    this.player.playing = true;
    const progressElement = song.container.querySelector('.wave-progress');
    const controlProgressElement = this.controlSong.querySelector('.wave-progress');
    this.progressBar(song, progressElement, controlProgressElement);
    this.playEvents();
  }

  loadSong(song) {
    if (this.player.song != song.id) {
      if (this.player.howl) {
        this.player.howl.pause();
        this.player.playing = false;
      }
      this.player.song = song.id;
      this.player.howl = song.howl;
      song.howl.load();
      song.container.classList.add('loading');
      this.controlSong.classList.add('loading');
    }
    song.howl.play();
    this.setControlBar();
  }

  progressBar(song, progressElement, controlProgressElement) {
    var seek = song.howl.seek() || 0;

    if (!userLoggedIn) {
      if (seek >= 30) {
        song.howl.pause()
        MicroModal.show('modal-signup');
      }
    }
    progressElement.style.width = (((seek / song.howl.duration()) * 100) || 0) + '%';
    controlProgressElement.style.width = (((seek / song.howl.duration()) * 100) || 0) + '%';
    if (song.howl.playing()) {
       requestAnimationFrame(() => {
         this.progressBar(song, progressElement, controlProgressElement)
       });
     }
  }

  seekTo(song, x) {
    var second = Math.round(song.howl.duration() * x);
    if (!userLoggedIn && second >= 30) {
      song.howl.pause()
      MicroModal.show('modal-signup');
    } else {
      song.howl.seek(second);
    }
  }

  stopAll() {
    this.player.playing = false;
    this.songs.forEach((song, index) => {
      song.howl.stop();
    });
  }

  setControlBar() {
    this.controlBar = document.querySelector('#player');
    this.controlSong = this.controlBar.querySelector('.song-row');

    if (!this.volumeSlider) {
      this.volumeSlider = this.controlSong.querySelector('input.slider');
      if (localStorage.getItem('volume')) {
        this.volumeSlider.value = localStorage.getItem('volume') * 100;
        this.updateVolumeIcon();
      }
      this.volumeSlider.addEventListener('change', e => {
        this.setVolume(this.volumeSlider.value / 100)
      });
      this.controlSong.querySelector('.volume .toggle').addEventListener('click', e => {
        if (this.player.volume > 0) {
          this.player.previousVolume = this.player.volume;
          this.setVolume(0);
        } else {
          this.player.volume = this.player.previousVolume;
          this.player.previousVolume = 0;
          this.setVolume(this.player.volume);
        }
      });
      document.addEventListener("keydown", event => {
        if (["BODY", "DIV"].includes(document.activeElement.tagName)) {
          if (event.keyCode === 32) {
            if (this.player.playing) {
              this.player.howl.pause();
              event.preventDefault();
            } else {
              if (this.player.howl) {
                this.player.howl.play();
                event.preventDefault();
              }
            }
          }
        }
      });
    }

    if (this.player.song != "") {
      var song = this.songs.find(song => song.id === this.player.song);
      const playPauseButton = this.controlSong.querySelector('.artwork-button');
      const waveformContainer = this.controlSong.querySelector('.song-waveform');

      playPauseButton.innerHTML = song.container.querySelector('.artwork-button').innerHTML;
      this.controlBar.querySelector('.song-duration').innerHTML = song.container.querySelector('.song-duration').innerHTML;
      this.controlBar.querySelector('.song-title h3').innerHTML = song.container.querySelector('.song-title h3').innerHTML;
      this.controlBar.querySelector('.user-actions').innerHTML = song.container.querySelector('.user-actions').innerHTML;
      this.controlBar.querySelector('.user-actions').innerHTML = song.container.querySelector('.user-actions').innerHTML;

      playPauseButton.addEventListener('click', event => {
        if (this.player.playing) {
          this.player.howl.pause()
        } else {
          this.player.howl.play()
        }
      });

      this.controlBar.style.display = "block";

      this.drawWaveform(this.controlSong, song.peaks);
      waveformContainer.removeEventListener('click', this.boundclickControlProgress);
      this.boundclickControlProgress = evt => this.clickControlProgress(evt, waveformContainer, song);
      waveformContainer.addEventListener('click', this.boundclickControlProgress);

    }
  }

  clickControlProgress(e, waveformContainer, song) {
    var x = ((e.pageX - waveformContainer.offsetLeft)/waveformContainer.offsetWidth);
    this.seekTo(song, x);
  }

  setVolume(value) {
    this.player.volume = value;
    this.player.howl.volume(value);
    this.volumeSlider.value = value * 100;
    localStorage.setItem('volume', value);
    this.updateVolumeIcon();
  }

  updateVolumeIcon() {
    const volumeElement = document.querySelector('.player .volume');
    if (this.player.volume == 0) {
      volumeElement.classList.add('mute');
    } else {
      volumeElement.classList.remove('mute');
    }
  }

  playEvents() {
    if (typeof fbq !== 'undefined') {
      fbq('track', 'ViewContent',{
        content_ids: this.player.song.replace('song_',''),
        content_type: 'product'
      });
    }
  }

}

export default songsPlayer